import { AuthorizeComponent } from './authorization/authorize/authorize.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { LoginComponent } from './authentication/login/login.component';
import { AuthenticatedLayoutComponent } from './layout/authenticated-layout/authenticated-layout.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthorizationGuard } from './authorization/authorization.guard';
import { AuthorizationConstants } from './authorization/authorization-constants';
import { NavigationLandingComponent } from './authorization/navigation-landing/navigation-landing.component';

const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		redirectTo: "maps"
	},
	{
		path: "",
		component: BaseLayoutComponent,
		pathMatch: "prefix",
		children: [
			{
				path: "login",
				component: LoginComponent
			},
			{
				path: "logout",
				component: LogoutComponent
			},
			{
				path: "authorize",
				component: AuthorizeComponent,
				canActivate: [AuthenticationGuard]
			},
			{
				path: "features",
				component: NavigationLandingComponent,
				canActivate: [
					AuthenticationGuard,
					AuthorizationGuard
				]
			},
			{
				path: "maps",
				component: AuthenticatedLayoutComponent,
				canActivateChild: [
					AuthenticationGuard,
					AuthorizationGuard
				],
				loadChildren: () => import("./maps/maps.module").then(module => module.MapsModule),
				data: {
					feature: AuthorizationConstants.LIVE_MAP_LICENSE
				}
			},
			{
				path: "crown",
				component: AuthenticatedLayoutComponent,
				canActivateChild: [
					AuthenticationGuard,
					AuthorizationGuard
				],
				loadChildren: () => import("./crown/crown-user-management.module").then(module => module.CrownUserManagementModule)
			},
			{
				path: "user-management",
				component: AuthenticatedLayoutComponent,
				canActivateChild: [
					AuthenticationGuard,
					AuthorizationGuard
				],
				loadChildren: () => import("./user-management/user-management.module").then(module => module.UserManagementModule),
				data: {
					feature: AuthorizationConstants.AGENT_USER_MANAGEMENT_LICENSE
				}
			},
			{
				path: "driver",
				component: AuthenticatedLayoutComponent,
				canActivate: [
					AuthenticationGuard,
					AuthorizationGuard
				],
				loadChildren: () => import("./driver/driver.module").then(module => module.DriverModule),
				data: {
					feature: AuthorizationConstants.MOBILE_DRIVER_LICENSE
				}
			},
			{
				path: "diagnostics-data",
				component: AuthenticatedLayoutComponent,
				loadChildren: () => import("./diagnostics-data/diagnostics.module").then(module => module.DiagnosticsModule),
				data: {
					feature: AuthorizationConstants.MOBILE_DRIVER_LICENSE
				}
			},
			{
				path: "help",
				component: AuthenticatedLayoutComponent,
				loadChildren: () => import("./help/help.module").then(module => module.HelpModule)
			},
			{
				path: "forgot-password",
				loadChildren: () => import("./forgot-password/forgot-password.module").then(module => module.ForgotPasswordModule)
			},
			{
				path: "**",
				pathMatch: "full",
				component: PageNotFoundComponent
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
