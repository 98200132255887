/**
 * The constants for roles and licenses for authorization.
 */
export class AuthorizationConstants {

	public static readonly AGENT_MANAGEMENT_ROLE = "Agent Management";

	public static readonly CROWN_USER_MANAGEMENT_ROLE = "Crown User Management";

	public static readonly USER_ROLE_MANAGEMENT_ROLE = "User Role Management";

	public static readonly AGENT_LICENSE_BYPASS_ROLE = "Agent License Bypass";

	public static readonly CLIENT_EVENT_TELEMETRY_BYPASS_ROLE = "Client Event Telemetry Bypass";

	public static readonly LIVE_MAP_LICENSE = "Live Map";

	public static readonly OPTIMIZE_SINGLE_ROUTE_PLAN: string = "Optimize Single Route Plan";

	public static readonly ROUTE_PLANNING_LICENSE = "Route Planning";

	public static readonly AGENT_USER_MANAGEMENT_LICENSE = "Agent User Management";

	public static readonly MOBILE_DRIVER_LICENSE = "Mobile Driver";
}