/**
 * The identifiers used to identify telemetry values.
 */
export class TelemetryIdentifiers {

	public static readonly EVENT_UNAUTHENTICATED_REQUEST = "unauthenticated-request";

	public static readonly EVENT_LOGIN = "login";

	public static readonly EVENT_LOGOUT = "logout";

	public static readonly EVENT_FORGOT_PASSWORD = "forgot-password";

	public static readonly EVENT_FORGOT_PASSWORD_SEND_EMAIL = "forgot-password-send-email";

	public static readonly EVENT_FORGOT_PASSWORD_RESET_PASSWORD = "forgot-password-reset-password";

	public static readonly EVENT_LOGGED_IN_ELSEWHERE = "logged-in-elsewhere";

	public static readonly METRIC_USER_STATE_READ: string = "user-state-read";

	public static readonly METRIC_USER_STATE_WRITE: string = "user-state-write";

	public static readonly METRIC_DISPATCHED_SERVICES_STATE_READ: string = "dispatched-services-state-read";

	public static readonly METRIC_DISPATCHED_SERVICES_STATE_WRITE: string = "dispatched-services-state-write";

	public static readonly METRIC_DISPATCHED_SERVICES_SYNC_DATA_DELETE: string = "dispatched-services-sync-data-delete";

	public static readonly METRIC_DISPATCHED_SERVICES_SYNC_DATA_READ: string = "dispatched-services-sync-data-read";

	public static readonly METRIC_DISPATCHED_SERVICES_SYNC_DATA_WRITE: string = "dispatched-services-sync-data-write";

	public static readonly METRIC_DISPATCHED_SERVICES_CAPTURED_IMAGE_DATA_WRITE: string = "dispatched-services-captured-image-data-write";

	public static readonly METRIC_DISPATCHED_SERVICES_CACHED_DATA_READ: string = "dispatched-services-cached-data-read";

	public static readonly METRIC_DISPATCHED_SERVICES_CACHED_DATA_WRITE: string = "dispatched-services-cached-data-write";

	public static readonly EVENT_SELECTED_AGENT_CROWNID: string = "selected-agent-crownid";

	public static readonly EVENT_OPEN_DRIVER_FILTER: string = "open-driver-filter";

	public static readonly EVENT_OPEN_SURCHARGE_FILTER: string = "open-surcharge-filter";

	public static readonly EVENT_OPEN_ROUTE_FILTER: string = "open-route-filter";

	public static readonly EVENT_OPEN_MOVE_TYPE_FILTER: string = "open-move-type-filter";

	public static readonly EVENT_OPEN_STOP_STATUS_FILTER: string = "open-stop-status-filter";

	public static readonly EVENT_OPEN_DISPATCH_TERMINAL_FILTER: string = "open-dispatch-terminal-filter";

	public static readonly EVENT_OPEN_SERVICE_LEVELS_FILTER: string = "open-service-levels-filter";

	public static readonly EVENT_OPEN_MAP_FILTER_PANE: string = "open-map-filter-pane";

	public static readonly EVENT_UPDATE_MAP_WITH_FILTERS: string = "update-map-with-filters";

	public static readonly EVENT_CALCULATE_ETAS: string = "calculate-etas";

	public static readonly EVENT_OPTIMIZE_ROUTE: string = "optimize-route";

	public static readonly EVENT_SEND_AND_DISPATCH_ROUTE: string = "send-and-dispatch-route";

	public static readonly EVENT_SEND_ROUTE: string = "send-route";

	public static readonly EVENT_DISPATCH_ROUTE: string = "dispatch-route";

	public static readonly EVENT_UPSERT_ROUTE_MANIFEST: string = "upsert-route-manifest";

	public static readonly EVENT_SAVE_ROUTE: string = "save-route";

	public static readonly EVENT_DELETE_ROUTE: string = "delete-route";

	public static readonly EVENT_ADD_BREAK_TO_ROUTE: string = "add-break-to-route";

	public static readonly EVENT_OVERRIDE_TIME_TO_SERVICE: string = "override-time-to-service";

	public static readonly EVENT_FILTER_ROUTE_TOTALS: string = "filter-route-totals";

	public static readonly EVENT_OPEN_ROUTE_FROM_MAP: string = "open-route-from-map";

	public static readonly EVENT_OPEN_ROUTE_FROM_DRIVER_ROUTES: string = "open-route-from-driver-routes";

	public static readonly EVENT_CREATE_NEW_ROUTE: string = "create-new-route";

	public static readonly EVENT_CREATE_NEW_ROUTE_FROM_IMPORT: string = "create-new-route-from-import";

	public static readonly EVENT_OPEN_GEOCODING_FAILURES_WINDOW: string = "open-geocoding-failures-window";

	public static readonly EVENT_SAVE_GEOCODING_FAILURE_ADDRESS_FIX: string = "save-geocoding-failure-address-fix";

	public static readonly EVENT_SAVE_GEOCODING_FAILURE_ASSIGNED_LOCATION: string = "save-geocoding-failure-assigned-location";

	public static readonly EVENT_SELECT_ADDRESS_SUGGESTION: string = "select-address-suggestion";

	public static readonly EVENT_SELECT_MAP_VIEW_FILTER: string = "select-map-view-filter";

	public static readonly EVENT_SAVE_MAP_VIEW_FILTER: string = "save-map-view-filter";

	public static readonly EVENT_DELETE_MAP_VIEW_FILTER: string = "delete-map-view-filter";

	public static readonly EVENT_SAVE_STOP_MOVE: string = "save-stop-move";

	public static readonly EVENT_CALCULATE_ALL_ETAS: string = "calculate-all-etas";

	public static readonly EVENT_CHANGE_MAP_DATE: string = "change-map-date";

	public static readonly EVENT_OPEN_SATELLITE_VIEW: string = "open-satellite-view";

	public static readonly EVENT_FILTER_VIEW_ROUTE_PLANS: string = "filter-view-route-plans";

	public static readonly EVENT_FILTER_VIEW_STOP_NUMBERS: string = "filter-view-stop-numbers";

	public static readonly EVENT_USE_GPS_HISTORY: string = "use-gps-history";

	public static readonly EVENT_OPEN_LIVE_MAP_FROM_MENU: string = "open-live-map-from-menu";

	public static readonly EVENT_SEND_DIAGNOSTICS_DATA_MENU: string = "send-diagnostics-data-menu";

	public static readonly EVENT_OPEN_TIME_TO_SERVICE_CONFIGURATION_FROM_MENU: string = "open-time-to-service-configuration-from-menu";

	public static readonly EVENT_OPEN_CROWN_X_DRIVER_FROM_MENU: string = "open-crown-x-driver-from-menu";

	public static readonly EVENT_OPEN_USER_MANAGEMENT_FROM_MENU: string = "open-user-management-from-menu";

	public static readonly EVENT_OPEN_SURVEY_FROM_MENU: string = "open-survey-from-menu";

	public static readonly EVENT_OPEN_HELP_FROM_MENU: string = "open-help-from-menu";

	public static readonly EVENT_SAVE_TIME_TO_SERVICE_CONFIGURATIONS: string = "save-time-to-service-configurations";

	public static readonly EVENT_SENDING_DIAGNOSTICS_DATA: string = "sending-diagnostics-data";

	public static readonly EVENT_SAVE_USER: string = "save-user";

	public static readonly EVENT_IMPORT_DRIVERS_FROM_CFM: string = "import-drivers-from-cfm";

	public static readonly EVENT_GET_DRIVER_PERMISSIONS: string = 'get-driver-permissions';

	public static readonly EVENT_REFRESH_DRIVER_DISPATCHED_SERVICES: string = "refresh-driver-dispatched-services";

	public static readonly EVENT_OPEN_DRIVER_SERVICES_PREVIEW_FROM_NAVIGATION_BAR: string = "open-driver-services-preview-from-navigation-bar";

	public static readonly EVENT_OPEN_DRIVER_END_OF_ROUTE_FROM_NAVIGATION_BAR: string = "open-driver-end-of-route-from-navigation-bar";

	public static readonly EVENT_OPEN_DRIVER_EN_ROUTE_TO_STOP_FROM_NAVIGATION_BAR: string = "open-driver-en-route-to-stopfrom-navigation-bar";

	public static readonly EVENT_SHOW_DRIVER_ROUTE_LIST: string = "show-driver-route-list";

	public static readonly EVENT_SHOW_DRIVER_SERVICE_DETAILS: string = "show-driver-service-details";

	public static readonly EVENT_FINISH_SERVICE_FROM_PERFORM_SERVICE_PAGE: string = "finish-service-from-perform-service-page";

	public static readonly EVENT_CANCEL_FROM_PERFORM_SERVICE_PAGE: string = "cancel-from-perform-service-page";

	public static readonly EVENT_DRIVER_COMPLETE_ROUTE: string = "driver-complete-route";

	public static readonly EVENT_DRIVER_RETURN_TO_PREVIEW: string = "driver-return-to-preview"

	public static readonly EVENT_DRIVER_LOGOUT: string = "driver-logout";

	public static readonly EVENT_ACCEPT_ALL_SERVICES_FROM_SERVICE_PREVIEW_TOOLBAR: string = "accept-all-services-from-service-preview-toolbar";

	public static readonly EVENT_APPLY_FILTERS_FROM_SERVICE_PREVIEW_TOOLBAR: string = "apply-filters-from-service-preview-toolbar";

	public static readonly EVENT_START_ROUTE_FROM_SERVICE_PREVIEW_PAGE: string = "start-route-from-service-preview-page";

	public static readonly EVENT_ACCEPT_SERVICE_FROM_SERVICE_PREVIEW_PAGE: string = "accept-service-from-service-preview-page";

	public static readonly EVENT_SERVICE_REORDERED_ON_SERVICE_PREVIEW_PAGE: string = "service-reordered-from-service-preview-page";

	public static readonly EVENT_ARRIVE_SERVICE_FROM_EN_ROUTE_TO_STOP_PAGE: string = "arrive-service-from-en-route-to-stop-page"

	public static readonly EVENT_CANCEL_SERVICE_ARRIVAL_FROM_EN_ROUTE_TO_STOP_PAGE: string = "cancel-service-arrival-from-en-route-to-stop-page"

	public static readonly EVENT_PERFORM_SERVICE_FROM_EN_ROUTE_TO_STOP_PAGE: string = "perform-service-from-en-route-to-stop-page"

	public static readonly EVENT_CANNOT_COMPLETE_NOTES_SAVED_FROM_EN_ROUTE_TO_STOP_PAGE: string = "cannot-complete-notes-saved-from-en-route-to-stop-page"

	public static readonly EVENT_SEND_EMAIL_FOR_EDOCS: string = "send-email-for-edoc";

	public static readonly EVENT_SEND_DRIVER_DIAGNOSTICS: string = "send-driver-diagnostics";
}