import { ToastService } from './../../toast/toast.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TitleService } from '../../layout/title.service';
import { AuthenticationService } from '../authentication.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoggerService } from '../../telemetry/logger.service';
import { UserStateService } from '../../user-state/user-state.service';
import { TelemetryIdentifiers } from '../../telemetry/telemetry-identifiers';
import { WakeLockService } from 'src/app/driver/services/state/wake-lock.service';
import { EmailInputDirective } from 'src/app/common/email-input.directive';

/**
 * This component is used to login to the application.
 */
@Component({
	selector: 'crownx-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

	/** The model used for the login form. */
	public LoginModel = {
		UserName: "",
		Password: ""
	};

	/** Indicates if the credentials entered were rejected by the server. */
	public CredentialsAreInvalid: boolean = false;

	/** Indicates if the user has been locked out. */
	public UserIsLockedOut: boolean = false;

	/** Indicates if the user is logging in. */
	public IsLoggingIn: boolean = false;

	/** Used to unsubscribe from observables. */
	private unsubscriber = new Subject();

	/**
	 * @constructor
	 *
	 * @param authenticationService The authentication service.
	 * @param router The Angular router service.
	 * @param titleService The title service.
	 * @param toastService The toast service.
	 * @param logger The logging service.
	 * @param userStateService The user state service.
	 * @param wakeLock The WakeLock service used to keep the screen on.
	 */
	constructor(
		private readonly authenticationService: AuthenticationService,
		private readonly router: Router,
		private readonly titleService: TitleService,
		private readonly toastService: ToastService,
		private readonly logger: LoggerService,
		private readonly userStateService: UserStateService,
		private readonly wakeLock: WakeLockService
	) {}

	/**
	 * Component initialization.
	 */
	public ngOnInit(): void {
		this.titleService.Title = null;

		this.authenticationService.GetAuthentication$(true)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (user) => {
					this.router.navigate(["/authorize"]);
				},
				error: (error) => { }
			});
	}

	/**
	 * Component destruction.
	 */
	public ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	/**
	 * Determines if an email address (Username) is valid or not
	 * @returns True if the email matches the Email Input Directive Pattern
	 * @readonly
	 */
	public get IsEmailValid(): boolean {
		return Boolean(this.LoginModel.UserName.match(EmailInputDirective.PATTERN));
	}

	/**
	 * Event handler for the login button click event.
	 */
	public OnLoginButtonClick(): void {
		this.IsLoggingIn = true;
		this.authenticationService.Login$(this.LoginModel.UserName, this.LoginModel.Password)
			.subscribe({
				next: (authInfo) => {
					if (authInfo.IsAuthenticated) {
						this.userStateService.ClearState();
						this.userStateService.SetUsername(authInfo.UserName);
						// Added for IOS users to initialized the WakeLock since users needs to manually enable WakeLock.
						// Disables WakeLock immediately in the whole of CrownX. WakeLock gets reenabled only in CrownX Driver
						if (this.wakeLock.IsWakeLockSupportedAndNotSet) {
							this.wakeLock.RequestWakeLock$()
								.pipe(takeUntil(this.unsubscriber))
								.subscribe({
									next: () => this.wakeLock.ReleaseWakeLock$()
											.pipe(takeUntil(this.unsubscriber), finalize(() => this.router.navigate(["/authorize"])))
											.subscribe(),
									error: () => this.router.navigate(["/authorize"])
								});
						}
						else {
							this.router.navigate(["/authorize"]);
						}
					}
					else if (authInfo.IsLockedOut) {
						this.UserIsLockedOut = true;
						this.CredentialsAreInvalid = true;
					}
					else {
						this.CredentialsAreInvalid = true;
						this.UserIsLockedOut = false;
						this.IsLoggingIn = false;
					}
				},
				error: (error: Error) => {
					this.IsLoggingIn = false;
					this.toastService.ShowError("There was an error with your login attempt.");
					this.logger.TrackError("An error occurred while logging in.", error, { "UserName": this.authenticationService.UserName });
				}
			});


	}

	/**
	 * Represents the version number of crown-x in the current environment.
	 *
	 * @readonly
	 */
	public get VersionNumber(): string {
		return environment.Version;
	}

	/**
	 * Tracks when a user clicks forgot password and tracks an event.
	 */
	public TrackForgotPasswordEvent(): void {
		this.logger.TrackEvent(TelemetryIdentifiers.EVENT_FORGOT_PASSWORD, this.LoginModel.UserName.match(EmailInputDirective.PATTERN) != null ? { UserName: this.LoginModel.UserName } : {});
	}
}